@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@700&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

footer,
header,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Gothic A1', sans-serif;
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: .45em;
}

h2 {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: .3em;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  h2 {
    font-size: 20px;
  }
}

h3 {
  font-size: 16px;
  line-height: 1;
}

p {
  margin-top: 0;
  margin-bottom: 10px;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-inline-start: 14px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
  list-style-type: disc;
}

li {
  margin-bottom: 8px;
}

b,
strong {
  font-weight: bolder;
}

a:hover {
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button {
  border-radius: 0;
  appearance: none;
	background: none;
	padding: 0;
	border: none;
}
